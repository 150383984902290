import * as React from "react";
import { useId } from "@react-aria/utils";
import * as Sentry from "@sentry/browser";
import { fromUnixTime, format } from "date-fns";
import { AnimatePresence } from "framer-motion";
import { loadStripe } from "@stripe/stripe-js";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { FocusScope } from "@react-aria/focus";

import { Elements, ElementsThemed, Fragments, HOCs } from "components";
import {
  displayScheduledAtTime,
  displayScheduledAtTimeDelivery,
  formatTime,
} from "utils/helpers/time";
import { transformRequestError } from "utils/helpers/other";
import { config, constants, Copy, Routes, images } from "utils";
import { ConditionalWrapper } from "components/elements";
import { BottomSheet } from "components/fragments";
import {
  analytics,
  useResource,
  usePollingCheckout,
  useCheckPriceQuery,
} from "hooks";
import { useCell } from "hooks/useTemplate";
import { CheckoutProvider } from "components/providers/CheckoutType2";
import { usePatronContext } from "components/providers/Patron/PatronContext";
import GiftCardModal from "../Order/GiftCard/GiftCardModal/GiftCardModal";
import GiftCardField from "../Order/GiftCard/GiftCardField/GiftCardField";
import GiftCardSuccessPopup from "../Order/GiftCard/GiftCardSuccessPopup/GiftCardSuccessPopup";

import {
  getAvailableTipByOrderType,
  mapOrder,
  mapOrderValidation,
} from "../Order/utils";
import css from "./checkout.module.scss";
import commonStyles from "../index.module.scss";
import Field from "../Order/Field";
import PriceField from "../Order/components/PriceField";
import {
  errorActions,
  errorReducer,
  getFinalAmount,
  getFinalAppliedStoreValue,
  orderSubmission,
  orderSummaryHeader,
  orderValidation,
} from "./utils";
import { CheckoutButton } from "./components/CheckoutButton";
import { CustomTipping } from "./components/custom-tipping/CustomTipping.tsx";

const { withTemplate } = HOCs;

const {
  Condition: { Condition: If },
  Tooltip,
  Overlay,
} = Elements;

const { Loader, BeamImpact, Image } = Fragments;
const { Text, View, Radio, Button } = ElementsThemed;

const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK },
} = constants;

const INIT_ERROR = {
  buttonText: "",
  message: "",
  type: null,
};

const initTip = (orderType, preDiscountPrice) => {
  let tipPercent = 0;

  switch (orderType) {
    case DELIVERY:
      tipPercent = config.delivery.tip_default;
      break;
    case PICKUP:
      tipPercent = config.pickup.tip_default;
      break;
    case KIOSK:
      tipPercent = config.kiosk.tip_default;
      break;
    default:
      tipPercent = 0;
  }
  return (Number(tipPercent) * preDiscountPrice).toFixed(2);
};

const Checkout = ({
  style,
  onSuccess,
  setHeader,
  orderContext,
  history,
  cart,
  beam,
  setBeam,
  showDiscount = true,
}) => {
  // Actions
  const toDiscount = () => history.push(Routes.FETCH_DISCOUNT);
  const clearDiscount = () => history.push(Routes.CLEAR_DISCOUNT);
  const toEditTableNumber = () => history.push(Routes.SET_TABLE_NUMBER);
  const { labelTextStyles } = useCell("cartHeader");

  const patron = usePatronContext();
  const elementId = useId();
  const { isLoggedIn } = patron;
  const { views, labels, cells, buttons, inputs } = style;
  const [error, dispatchError] = React.useReducer(errorReducer, INIT_ERROR);
  const [showCustomTipModal, setShowCustomTipModal] = React.useState(false);
  const [resource, setResource] = React.useState({});
  const [isMobilePay, setIsMobilePay] = React.useState(
    localStorage.getItem("isMobilePay") === "true",
  );
  const { resetOrder } = orderContext;
  const [availablePaymentTypes, setAvailablePaymentTypes] = React.useState({});
  const [buttonDisabled, setButtonDisabled] = React.useState(!isLoggedIn);
  const [invalidDiscount, setInvalidDiscount] = React.useState(false);
  const [isCash, setIsCash] = React.useState(false);
  const [ordering, setOrdering] = React.useState(false);
  const { idempotencyKey, diningOption } = orderContext.order;
  const locationId = orderContext.location.id;

  const [isGiftCardModalOpen, setGiftCardModalOpen] = React.useState(false);
  const [isGiftCardSuccessPopupOpen, setGiftCardSuccessPopupOpen] =
    React.useState(false);

  const [tip, setTip] = React.useState(
    initTip(
      diningOption,
      Object.keys(resource ?? {}).length && resource.preDiscountPrice
        ? resource.preDiscountPrice
        : 0,
    ),
  );
  const scrollingRef = React.useRef();
  const checkPriceQuery = useCheckPriceQuery();

  const autoScroll = () => {
    const container = scrollingRef.current;
    if (container) container.scrollTop = container.scrollHeight;
  };

  let tipAmount = tip;
  let showTip = true;

  if (
    (diningOption === PICKUP && !config.pickup.tip) ||
    (diningOption === DELIVERY && !config.delivery.tip) ||
    (diningOption === KIOSK && !config.kiosk.tip)
  ) {
    showTip = false;
    tipAmount = 0;
  }

  const tipsAvailableOptions = getAvailableTipByOrderType(
    orderContext?.order?.diningOption,
  );

  const mobilePayEnabled =
    isMobilePay && Object.keys(availablePaymentTypes).length;

  const { validationError, serverError, clearErrors } =
    errorActions(dispatchError);

  // orderflow v2  hoooks
  const orderError = (err) =>
    validationError({
      buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
      message: err.message,
    });
  // pollingcheckout hoooks
  const { pollingCheckout } = usePollingCheckout(
    idempotencyKey,
    resetOrder,
    onSuccess,
    setOrdering,
    serverError,
    orderError,
  );

  // set the order header
  const orderHeader = orderSummaryHeader(diningOption);
  // set the header when the component mount
  React.useEffect(() => {
    if (setHeader) setHeader(orderHeader);
  }, []);

  const validate = (res) => {
    const payload = orderValidation({
      diningOption,
      isCash,
      locationId,
      mobilePayEnabled,
      res,
    });
    if (Object.keys(payload).length) {
      validationError(payload);
    } else {
      clearErrors();
    }
  };

  const placeOrder = async ({ transactionId = null }) => {
    setOrdering(true);
    try {
      const payload = {
        cart,
        idempotencyKey,
        locationId,
        isCash,
        order: mapOrder(orderContext),
        tip: tipAmount,
        transactionId,
        giftCardNumber: orderContext?.order?.giftCard?.giftCardNumber || "",
        giftCardPinCode: orderContext?.order?.giftCard?.pinCode || "",
      };

      const { data } = await orderSubmission(payload);

      // when the polling is set to true use orderflow v2
      if (config?.ordering?.polling) {
        await pollingCheckout();
      } else {
        resetOrder();
        onSuccess(data);
      }
    } catch (err) {
      const e = transformRequestError(err);
      Sentry.captureException(err);

      if (e.message) {
        validationError({
          buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
          message: e.message,
        });
      } else {
        serverError();
      }
      setOrdering(false);
    }
  };

  // TODO: implement error message display for validateOrder
  // check if it's even needed
  const [validateOrder] = useResource(
    {
      data: mapOrderValidation(orderContext),
      headers: {
        locationId,
      },
      method: "post",
      path: Routes.VALIDATE_ORDER,
    },
    [],
    ({ resource, error }) => {
      if (error?.data) {
        setButtonDisabled(true);
        // setErrorMessage((isKnownReason(error?.raw?.reason) && error?.raw?.message) || Copy.CART_STATIC.CART_ERROR_MESSAGE);
      } else if (
        (resource?.discount && resource?.discount?.status !== "valid") ||
        resource?.isValid !== true ||
        resource?.reason
      ) {
        setInvalidDiscount(true);
        setButtonDisabled(true);
        // setErrorMessage((isKnownReason(resource?.reason) && resource?.message) || Copy.CART_STATIC.CART_ERROR_MESSAGE);
      } else {
        setButtonDisabled(false);
        // setErrorMessage("");
      }
    },
  );

  // Validate Order
  // TODO: Move this to the useResource Hook
  React.useEffect(() => {
    if (!validateOrder || (validateOrder && validateOrder.fetching)) {
      // empty
    } else {
      if (validateOrder.error && validateOrder.error.data) {
        validationError({
          buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
          message: Copy.CART_STATIC.CART_ERROR_MESSAGE,
        });
      }
      if (validateOrder && validateOrder.resource) {
        if (
          validateOrder.resource.reason &&
          [
            "pickup-closed",
            "delivery-closed",
            "pickup-unavailable",
            "delivery-unavailable",
            "invalid-scheduledAt",
            "items",
            "delivery-radius",
            "unavailable",
            "order-minimum",
          ].includes(validateOrder.resource.reason)
        ) {
          validationError({
            buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
            message: validateOrder.resource.message,
          });
        } else if (
          validateOrder.resource &&
          validateOrder.resource.isValid !== undefined &&
          !validateOrder.resource.isValid
        ) {
          validationError({
            buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
            message: validateOrder.resource.message,
          });
        }
      }
    }
  }, [validateOrder.fetching]);

  // Check Price
  // TODO: Move this to the useResource Hook
  React.useEffect(() => {
    if (checkPriceQuery.isFetching) {
      // empty
    } else if (checkPriceQuery.error && checkPriceQuery.error.data) {
      validationError({
        buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
        message: Copy.CART_STATIC.CART_ERROR_MESSAGE,
      });
    } else if (
      checkPriceQuery.data &&
      checkPriceQuery.data.isValid !== undefined &&
      !checkPriceQuery.data.isValid
    ) {
      validationError({
        buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
        message: validateOrder.resource.message,
      });
    } else {
      analytics.checkout.started({
        items: orderContext?.items,
        resource: checkPriceQuery?.data,
      });
      validate(checkPriceQuery.data);
      setResource(checkPriceQuery.data);
    }
  }, [checkPriceQuery.isFetching]);

  React.useEffect(() => {
    validate(resource);
    if (isCash) {
      setTip(0);
    }
  }, [isCash]);

  React.useEffect(() => {
    setTip(
      initTip(
        diningOption,
        Object.keys(resource).length && resource.preDiscountPrice
          ? resource.preDiscountPrice
          : 0,
      ),
    );
  }, [resource.preDiscountPrice]);

  React.useEffect(() => {
    validate(resource);
  }, [resource, availablePaymentTypes, isMobilePay]);

  const [{ resource: locationCreds }] = useResource({
    data: {},
    method: "get",
    path: "/location/credentials",
  });

  const [stripePromise, setstripePromise] = React.useState(
    Promise.resolve(null),
  );

  React.useEffect(() => {
    if (locationCreds?.publicKey) {
      setstripePromise(loadStripe(locationCreds?.publicKey));
    }
  }, [locationCreds]);

  const {
    appliedCredit = 0,
    appliedDiscounts = 0,
    appliedStoredValue = 0,
    customerCard = null,
    delivery = 0,
    preDiscountPrice = 0,
    serviceEstimate = 0,
    taxAmount = 0,
    totalAmount = 0,
  } = resource;
  let ccText = "No Card";
  if (isCash) {
    ccText = "Paying with Cash";
  } else if (customerCard && customerCard.last4) {
    ccText = `${customerCard.brand} ending in ${customerCard.last4}`;
  }

  const { street1, street2, city, state, zip } = orderContext.order.address;
  const {
    order: { scheduledAt },
    location: { timeZone },
  } = orderContext;
  const isDelivery = diningOption === DELIVERY;
  const serviceFeeInformation = isDelivery
    ? Copy.RG_INFO_STATIC.DELIVERY_INFORMATION_MESSAGE
    : Copy.RG_INFO_STATIC.PICKUP_INFORMATION_MESSAGE;

  const priceFieldName = isDelivery
    ? Copy.RG_INFO_STATIC.SERVICE_FEES_MESSAGE_DELIVERY
    : Copy.RG_INFO_STATIC.SERVICE_FEES_MESSAGE;

  const deliveryAddress = `${street1} ${street2} ${city} ${state} ${zip}`;
  const orderTypeText = isDelivery ? "Delivering to" : "Order Information";
  const orderTypeValue = isDelivery
    ? deliveryAddress
    : diningOption === PICKUP
    ? "Pickup"
    : Copy.KIOSK_STATIC.CHECKOUT_ORDER_TYPE;
  const preServiceFee = preDiscountPrice;
  const scheduleAtFormatter = isDelivery
    ? displayScheduledAtTimeDelivery
    : displayScheduledAtTime;

  let labelValue = isDelivery
    ? deliveryAddress
    : `ASAP (${formatTime(serviceEstimate)})`;

  if (scheduledAt) {
    const formattedScheduledAtDate = format(
      fromUnixTime(scheduledAt),
      "EEE, M/d/yyyy",
    );
    const formattedScheduledAt = `${formattedScheduledAtDate} ${scheduleAtFormatter(
      scheduledAt,
      timeZone,
    )}`;
    labelValue = formattedScheduledAt;
  } else {
    labelValue = `ASAP (${formatTime(serviceEstimate)})`;
  }

  if (validateOrder.fetching) {
    return (
      <View type={views.background} className={commonStyles.cartContainer}>
        <div className={css.loader}>
          <Loader />
        </div>
      </View>
    );
  }

  const onPaymentFailed = (err) => {
    if (err) {
      console.error(err);
    }
    setOrdering(false);
    validationError({
      buttonText: Copy.CART_STATIC.CHECKOUT_PLACE_ORDER_BUTTON_TEXT,
      message: Copy.CHECKOUT_STATIC.PAYMENT_FAILED_ERROR_TEXT,
    });
  };

  // Gift Card
  const isGiftCardAdded = orderContext?.order?.giftCard?.giftCardNumber;
  const giftCardBalance = orderContext?.order?.giftCard?.balance;
  const giftCardFieldValue = isGiftCardAdded
    ? `Giftcard ending in ${isGiftCardAdded.slice(isGiftCardAdded.length - 4)} `
    : "Redeem A Gift Card";
  // Possible giftCardType values are "modal" and "inline"
  const giftCardType = config?.theme?.checkout?.gift_card_type ?? "modal";

  const finalTip = Number.isNaN(parseFloat(tipAmount))
    ? 0
    : parseFloat(tipAmount);

  const finalAmount = getFinalAmount({
    appliedStoredValue,
    delivery,
    finalTip,
    giftCardBalance,
    totalAmount,
  });

  const finalAppliedStoreValue = getFinalAppliedStoreValue({
    appliedStoredValue,
    delivery,
    finalTip,
    giftCardBalance,
  });

  return (
    <ConditionalWrapper
      condition={stripePromise}
      wrapper={(children) => (
        <StripeElements stripe={stripePromise}>{children}</StripeElements>
      )}
    >
      <CheckoutProvider
        ordering={ordering}
        setOrdering={setOrdering}
        tipAmount={tipAmount}
        finalAmount={finalAmount}
        placeOrder={placeOrder}
        onPaymentFailed={onPaymentFailed}
        availablePaymentTypes={availablePaymentTypes}
        setAvailablePaymentTypes={setAvailablePaymentTypes}
        usePaymentRequest={
          config?.payment_processor === "stripe" &&
          config?.ordering?.mobile_pay &&
          finalAmount
        }
      >
        <View type={views.background} className={commonStyles.cartContainer}>
          <View
            type={views.background}
            className={css.content}
            innerRef={scrollingRef}
          >
            <Field
              type={cells.primary}
              label={orderTypeText}
              value={orderTypeValue}
              primaryText={cells.primary}
            />
            <br />

            <If is={diningOption === KIOSK}>
              <Field
                type={cells.primary}
                label={Copy.KIOSK_STATIC.CHECKOUT_TEXT}
                value={orderContext.order.table.number || ""}
                buttonProps={{
                  children: "Edit",
                  onClick: toEditTableNumber,
                }}
              />
              <br />
            </If>

            <If is={diningOption !== KIOSK && config.ordering.scheduled}>
              <Field
                type={cells.primary}
                label="Scheduled Time"
                value={labelValue}
                buttonProps={{
                  children: "Edit",
                  onClick: () => history.push(Routes.FETCH_SCHEDULE_DATE),
                }}
              />
              <br />
            </If>

            <Field
              type={cells.primary}
              label="Location"
              value={orderContext.location.name}
            />

            <br />

            <Overlay
              clickable
              onClick={() =>
                !mobilePayEnabled && history.push(Routes.FETCH_CARDS)
              }
            >
              <Field
                className="payment-details"
                type={cells.primary}
                label={mobilePayEnabled ? "Payment Method" : "Card"}
                value={
                  mobilePayEnabled
                    ? availablePaymentTypes?.applePay
                      ? "Apple Pay"
                      : "Mobile Payment"
                    : ccText
                }
                disabled={isCash}
                buttonProps={{
                  onClick: () => {
                    !mobilePayEnabled && history.push(Routes.FETCH_CARDS);
                  },
                  ...(!mobilePayEnabled && { children: "Edit" }),
                }}
              />
            </Overlay>

            <br />

            {config.id === "milkbread" && (
              <Text type={labels.error}>
                {Copy.CHECKOUT_STATIC.CHECKOUT_INFO}
              </Text>
            )}

            <If is={config?.theme?.checkout?.gift_card === true}>
              {giftCardType === "modal" ? (
                <Field
                  type={cells.primary}
                  label="Gift Card"
                  value={giftCardFieldValue}
                  buttonProps={{
                    onClick: isGiftCardAdded
                      ? orderContext.resetGiftCard
                      : () => setGiftCardModalOpen(true),
                    children: isGiftCardAdded ? "Remove" : "Add",
                  }}
                />
              ) : (
                <GiftCardField
                  onGiftCardAdded={({ balance, giftCardNumber, pinCode }) => {
                    orderContext.setGiftCard({
                      balance,
                      giftCardNumber,
                      pinCode,
                    });
                    setGiftCardSuccessPopupOpen(true);
                  }}
                  onGiftCardRemoved={orderContext.resetGiftCard}
                  isPinCodeRequired={orderContext?.location?.isPinCodeRequired}
                  giftCard={orderContext?.order?.giftCard}
                />
              )}
            </If>

            <If is={Object.keys(availablePaymentTypes).length}>
              <br />
              <Radio
                type={buttons.mobile}
                label={
                  <Text type={labels.mobile}>
                    {availablePaymentTypes?.applePay
                      ? "Pay with Apple Pay"
                      : "Use Mobile Payment"}
                  </Text>
                }
                className={css.radio}
                value={isMobilePay}
                onChange={() => {
                  setIsMobilePay(!isMobilePay);
                  if (localStorage)
                    localStorage.setItem("isMobilePay", !isMobilePay);
                }}
              />
            </If>

            <br />

            <If condition={config.ordering.cash}>
              <Radio
                type={buttons.cash}
                label={
                  <Text type={labels.cash}>
                    {Copy.CART_STATIC.PAY_WITH_CASH_OPTION_TEXT}
                  </Text>
                }
                className={css.radio}
                value={isCash}
                onChange={() => setIsCash(!isCash)}
              />
            </If>
            <br />
            {error.message && (
              <div>
                {autoScroll()}
                <Text type={labels.error}>{error.message}</Text>
              </div>
            )}

            <If condition={config?.apps?.beam_impact}>
              <div style={{ overflowX: "hidden", overflowY: "auto" }}>
                <BeamImpact
                  widgetId={config.apps.beam_impact}
                  patronEmail={patron?.patron?.email?.value}
                  cartTotal={finalAmount}
                  step="pre-checkout"
                  beam={beam}
                  setBeam={setBeam}
                />
              </div>
            </If>
          </View>

          {isGiftCardSuccessPopupOpen && (
            <>
              <div className={css.bottomSheetOverlay} style={{ top: 0 }} />
              <FocusScope contain restoreFocus autoFocus>
                <GiftCardSuccessPopup
                  onConfirm={() => setGiftCardSuccessPopupOpen(false)}
                />
              </FocusScope>
            </>
          )}
          <div className={css.footer}>
            <div className={css["price-container"]}>
              <PriceField
                type={labels.priceSubtotals}
                name={Copy.CART_STATIC.PRICE_SUBTOTAL_TEXT}
                value={preServiceFee}
              />

              <If condition={delivery > 0}>
                <PriceField
                  type={labels.priceSubtotals}
                  name={priceFieldName}
                  info={
                    serviceFeeInformation && (
                      <Tooltip
                        ariaLabel="service fee information"
                        ariaDescribedby={elementId}
                        message={serviceFeeInformation}
                        direction="right"
                      >
                        <Text
                          type={labels.disclaimer}
                          className={css.information}
                        >
                          ?
                        </Text>
                      </Tooltip>
                    )
                  }
                  value={`${delivery}`}
                />
              </If>

              <If condition={appliedCredit > 0}>
                <PriceField
                  type={labels.priceSubtotals}
                  name={Copy.CART_STATIC.LOYALTY_CREDIT_TEXT}
                  value={appliedCredit}
                  neg
                />
              </If>

              {orderContext.order.discount ? (
                <PriceField
                  type={labels.priceSubtotals}
                  name={Copy.CART_STATIC.PROMOS_DISCOUNTS_HEADER_TEXT}
                  error={invalidDiscount && Copy.CART_STATIC.INVALID_PROMO_CODE}
                  value={appliedDiscounts}
                  neg={appliedDiscounts > 0 && !invalidDiscount}
                  clear={
                    // TODO: Move this to its own constant component within checkout.
                    <Image
                      src={images?.button_price_clear}
                      className={css["checkout-summary--clear"]}
                      onClick={clearDiscount}
                      mediaName="button_price_clear"
                      mediaType="svg"
                      alt="close"
                    />
                  }
                />
              ) : (
                showDiscount && (
                  <div className={css["checkout-summary--row"]}>
                    <Text
                      type={labels.priceSubtotals}
                      className={css["checkout-summary--left"]}
                    >
                      {Copy.CART_STATIC.PROMOS_DISCOUNTS_HEADER_TEXT}
                    </Text>
                    <Button
                      type={buttons.promo}
                      onClick={toDiscount}
                      className={css["checkout-summary--right"]}
                    >
                      {Copy.CART_STATIC.ADD_PROMO_CODE_BUTTON_TEXT}
                    </Button>
                  </div>
                )
              )}

              <PriceField
                type={labels.priceSubtotals}
                name="Tax"
                value={taxAmount}
              />

              <If condition={appliedStoredValue}>
                <PriceField
                  type={labels.priceSubtotals}
                  name={Copy.CART_STATIC.GIFT_CARD_TEXT}
                  value={finalAppliedStoreValue}
                  neg
                />
              </If>

              <If condition={showTip && !isCash}>
                <CustomTipping
                  tipsAvailableOptions={tipsAvailableOptions}
                  tip={tip}
                  labels={labels}
                  setShowCustomTipModal={setShowCustomTipModal}
                  setTip={setTip}
                  totalAmount={resource.preDiscountPrice}
                  diningOption={diningOption}
                />
              </If>

              <PriceField
                type={labels.priceTotals}
                name={Copy.CART_STATIC.TOTAL_PRICE_TEXT}
                value={finalAmount}
              />
            </div>

            {showCustomTipModal ? null : (
              <CheckoutButton
                type={cells.bottom}
                error={error}
                ordering={ordering}
                setOrdering={setOrdering}
                finalAmount={finalAmount}
                tipAmount={tipAmount}
                placeOrder={placeOrder}
                onPaymentFailed={onPaymentFailed}
                setAvailablePaymentTypes={setAvailablePaymentTypes}
                mobilePayEnabled={mobilePayEnabled}
                orderContext={orderContext}
                disabled={
                  orderContext?.order?.items?.length === 0 || buttonDisabled
                }
              />
            )}

            <AnimatePresence>
              {isGiftCardModalOpen && (
                <>
                  <div className={css.bottomSheetOverlay} />
                  <FocusScope contain restoreFocus autoFocus>
                    <BottomSheet
                      labelTextStyles={labelTextStyles}
                      onClose={() => setGiftCardModalOpen(false)}
                      title="Gift Card"
                    >
                      <GiftCardModal
                        onGiftCardAdded={({
                          balance,
                          giftCardNumber,
                          pinCode,
                        }) => {
                          orderContext.setGiftCard({
                            balance,
                            giftCardNumber,
                            pinCode,
                          });
                          setGiftCardModalOpen(false);
                          setGiftCardSuccessPopupOpen(true);
                        }}
                        isPinCodeRequired={
                          orderContext?.location?.isPinCodeRequired
                        }
                      />
                    </BottomSheet>
                  </FocusScope>
                </>
              )}
            </AnimatePresence>
          </div>
        </View>
      </CheckoutProvider>
    </ConditionalWrapper>
  );
};

export default withTemplate(Checkout, "checkout");
