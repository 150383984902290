import * as React from "react";
import { Copy, config } from "utils";
import Type1 from "./Type1";

const Notes = (props) => {
  const additionalProps = {
    description: Copy.ITEM_DETAILS_STATIC.NOTES_DESCRIPTION,
    label: Copy.ITEM_DETAILS_STATIC.NOTES_LABEL,
    placeholder: Copy.ITEM_DETAILS_STATIC.NOTES_PLACEHOLDER,
  };

  let Component = null;

  switch (config.theme.item_details.notes) {
    default:
      Component = Type1;
  }

  return <Component {...additionalProps} {...props} />;
};

export default Notes;
