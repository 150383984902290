import * as React from "react";
import { AnimatePresence } from "framer-motion";

import { Condition } from "components/elements";

import { withTemplate } from "components/hocs";
import { View, Segment, Text } from "components/elementsThemed";

import { config, Copy, images as imagesUtils } from "utils";

import NestedModifier from "components/templates/ItemDetails/NestedModifiers";
import BackButton from "../../../../BackButton/BackButton";
import TabSelector from "../../Tabs";
import HeaderSelector from "../../Header";
import FooterSelector from "../../Footer";
import Notes from "../../Notes";
import GlobalMessage from "../../globalMessage";

const { Condition: If } = Condition;

const ItemDetails = React.memo((props) => {
  const {
    addModifier,
    artMiscImage,
    buttonText,
    calories,
    closeDrawer,
    css,
    decQuantity,
    errors,
    globalError,
    handleScroll,
    hasQuantityLabel,
    incQuantity,
    isRewardItem,
    item,
    itemImageRef,
    modifications,
    nestedDrawer,
    notes,
    onChangeNotes,
    onSubmit,
    optionRefs,
    optionsHash,
    placeholderImage,
    price,
    quantity,
    removeModifier,
    scrollingRef,
    setGlobalError,
    style,
    tabs,
  } = props;
  const { segmentViews, views, cells, inputs, labels } = style;
  const {
    name,
    description,
    dietaryRestrictions,
    additionalDescriptions = [],
  } = item;
  const containerRef = React.useRef(null);
  const isCharLimit = notes.length >= 1000;

  return (
    <AnimatePresence>
      <View
        innerRef={scrollingRef}
        id="itemDetails"
        type={views.background}
        className={css.itemDetails}
      >
        <If is={props.showClose}>
          <div className={css["itemDetails-back"]}>
            <BackButton
              imgSrc={imagesUtils?.button_back_item_details}
              onClick={props.close}
            />
          </div>
        </If>
        <div
          id="itemDetailsContainer"
          className={css["itemDetails-content"]}
          ref={containerRef}
        >
          <img
            alt=""
            id="itemImage"
            ref={itemImageRef}
            className={css["itemDetails-image"]}
            style={{
              backgroundImage: `url(${placeholderImage})`,
            }}
          />

          <div id="header" className={css["itemDetails-header"]}>
            <div className={css["itemDetails-header--container"]}>
              <HeaderSelector
                additionalDescriptions={additionalDescriptions}
                description={description}
                dietaryRestrictions={dietaryRestrictions}
                price={price}
                name={name}
                calories={calories}
                cells={cells}
              />
            </div>
          </div>
          <div
            className={[css["itemDetails-tabs"], "itemDetails-tabs"].join(" ")}
          >
            <Segment
              type={segmentViews.standard}
              render={(segmentProps) => {
                if (!tabs.length) {
                  return config.ordering.notes ? null : (
                    <div
                      style={{
                        backgroundImage: `url(${artMiscImage})`,
                        backgroundPosition: "bottom",
                        backgroundRepeat: "no-repeat",
                        height: "100%",
                      }}
                    />
                  );
                }
                return (
                  <TabSelector
                    optionRefs={optionRefs}
                    handleScroll={handleScroll}
                    {...segmentProps}
                    tabs={tabs}
                    errors={errors}
                    cells={cells}
                    addModifier={addModifier}
                    removeModifier={removeModifier}
                    menuOptions={optionsHash}
                    modifications={modifications}
                    containerRef={containerRef}
                  />
                );
              }}
            />
            <If is={config.ordering.notes}>
              <div className={css["itemDetails-notes"]}>
                <Notes
                  label={Copy.ITEM_DETAILS_STATIC.NOTES_LABEL}
                  value={notes}
                  type={inputs.standard}
                  onChange={onChangeNotes}
                />
              </div>
            </If>
          </div>
        </div>
        {!!Object.keys(nestedDrawer).length && (
          <NestedModifier
            addModifier={addModifier}
            errors={errors}
            handleScroll={handleScroll}
            onClose={closeDrawer}
            optionRefs={optionRefs}
            optionsHash={optionsHash}
            parent={{
              item: nestedDrawer?.item,
              modifiers: nestedDrawer?.modifiers,
              option: nestedDrawer?.option,
              quantity: nestedDrawer?.quantity,
            }}
            removeModifier={removeModifier}
            showQuantity={nestedDrawer?.multiSelect}
            style={style}
          />
        )}
        <View type={views.footer} className={css["itemDetails-footer"]}>
          <GlobalMessage
            error={globalError}
            afterQueueSuccess={() => setGlobalError(null)}
            render={(error) => (
              <View
                type={views.background}
                className={css["itemDetails-error"]}
              >
                <Text type={labels.warning}>{error}</Text>
              </View>
            )}
          />
          <FooterSelector
            name={name}
            hasQuantityLabel={hasQuantityLabel}
            quantityType={cells.quantity}
            quantity={quantity}
            incQuantity={incQuantity}
            decQuantity={decQuantity}
            buttonType={cells.bottom}
            onClick={onSubmit}
            buttonText={buttonText()}
            data-cy="itemDetails-add-to-cart-button"
            showQuantity={isRewardItem ? false : props.showQuantity}
            isDisabled={isCharLimit}
          />
        </View>
      </View>
    </AnimatePresence>
  );
});

ItemDetails.defaultProps = {
  notes: "",
};
export default withTemplate(ItemDetails, "itemDetails");
